import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-2 text-center",attrs:{"height":"282"}},[_c('div',{staticClass:"headline my-5"},[_vm._v("Firelayer.io")]),_c(VBtn,{staticClass:"mr-2",attrs:{"x-large":"","href":"https://firelayer.io/docs","target":"_blank"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-book")]),_vm._v(" Documentation ")],1),_c(VBtn,{attrs:{"x-large":"","href":"https://github.com/firelayer/firelayer","target":"_blank"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-github")]),_vm._v(" Github ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }